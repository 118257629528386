import axiosInstances from '../config/axios'
// import { APP_INSTANCE_ARN } from "../config/awsChimeConfig"
import { MessagePayload } from '../components/Chat/types'
import { Buffer } from "buffer";
import { isAndroid, isIOS, osVersion} from "react-device-detect"
import { MessagingConstants } from './enums';
import { channel } from 'diagnostics_channel';
import { UI_ENV } from '../config/awsChimeConfig'
import axios from 'axios';
import messagingService from '../services/MessagingService';
import AWS from 'aws-sdk';
import * as keys from '../config/awsChimeConfig'

const { axiosInstance, jobseekerAxiosInstance } = axiosInstances
export const TIMEZONE = 'timezone'
export const TIMEZONE_ABBREV = 'timezoneAbbrev'
export const TIMEZONE_ALTNAME = 'timezoneAltName'
const STORED_MESSAGING_AUTH_KEY =  localStorage?.getItem(`${process.env.REACT_APP_ENV}-messaging`) || ""

export const getUserInstanceArnv2 = async (
  externalSystemUserId: string | null,
  role: string | null,
  messagingUserId: string | null
) => {
  const params = {
    "externalSystemUserId": externalSystemUserId,
    "role": role,
    "messagingUserId": messagingUserId
  }

  const messagingHeader = await validateToken(STORED_MESSAGING_AUTH_KEY)

   return await axiosInstance.post(`/user/lookup`, JSON.stringify(params),
    { headers : messagingHeader }
   ).then(res => {
      if (res && res.data) {
        return res.data
      }
    return null
  }).catch( err => {
    return err
  })
}

export const decryptUrl = async (token: string) => {
  const params = {"payload": token}
  return await axiosInstance.post(`/security/decrypt`, JSON.stringify(params), { headers : await getStoredMessagingAuth()}).then(res => {
    if(res && res.data) {
      return res.data
    }
  }).catch(err => {
    return err
  })
}

export const userArn = async (userId:string | null) => {
  try {
    const res = await axiosInstance.get(`/fetch/messaging-app`, { headers : await getStoredMessagingAuth()})
    if (res?.data?.response) {
      const messagingApp = await decryptData(res?.data?.response)
      return `${messagingApp}/user/${userId}`
    } return ''
  } catch (error) {
    console.log('Error retrieving user')
    return ''
  }
}

export const isIndicatorMessage = (messageContent: string) =>
  messageContent?.match(/\[Indicator/)

export const checkIfBlockIndicatorMessage = (messageContent: string) => {
  return Boolean(messageContent?.match(/\[Indicator:Block]/) || messageContent?.match(/\[Indicator:Unblock]/))
}


/* Retrieves userInstanceArn */
export const getUserInstanceArn = async (email: string) => {
  const params = { "email": email }
   return await axiosInstance.post(`/user/instance-arn/v2`, JSON.stringify(params), { headers : await getStoredMessagingAuth()}).then(res => {
    if (res && res.data) {
      return res.data
    }
    return null
  }).catch( err => {
    return null
  })
}

/* Splits the userId from the userArn */
export const splitUserArn = (userArn: string) => {
    if (!userArn) {
      return ""
    }
    const splitStr = userArn.split('/')
    if (!splitStr || splitStr.length == 0) {
      return ""
    }
    return splitStr[splitStr.length -1]
}

/* Retrieves messages of a channel */
export const listChannelMessages = async (
  channelArn: string,
  senderId: string
) => {
  const params = {
    "channelArn": channelArn,
    "messagingUserId": senderId
  }

  return await axiosInstance.post(`/channel/list-messages`, JSON.stringify(params), { headers : await getStoredMessagingAuth()}).then(res => {
    const messageList = res.data
    messageList.sort(function (a: MessagePayload, b:MessagePayload) {
      return a.CreatedTimestamp < b.CreatedTimestamp
        ? -1
        : a.CreatedTimestamp > b.CreatedTimestamp
        ? 1
        : 0
    })

    const messages = []
    for (let i = 0; i < messageList.length; i++) {
      const message = messageList[i]
      messages.push(message)
    }
    return { Messages: messages}
  }).catch( err => {
    return null
  })
}

/* creates/retrieves the channel name thru discussed pattern */
export const getChannelNamePattern = async (
  firstId: string,
  secondId: string,
  jobId: string
) => {
  const channelName = `${firstId}-${secondId}-${jobId}`
  return channelName?.replace(/\s/g, '')
}

/* splits the channelNamePattern and returns the IDs (senderId, receiverId, jobId)*/
export const splitChannelNamePattern = async (
  channelName: string
) => {
  const splitID = channelName.split('-')
  splitID.forEach(id => {
    
  })
}

/* sends channel message */
export async function sendChannelMessage(
  sender: {
    externalUserId: string,
    messagingId: string,
    role: string
  },
  receiver: {
    externalUserId: string,
    messagingId: string,
    role: string
  },
  channelArn: string,
  messageContent: string,
) {
  let sentMessage = null
  const params = {
    channelArn,
    messagingUserId: sender.messagingId,
    messageContent,
    persistence: "PERSISTENT", // Allowed types are PERSISTENT and NON_PERSISTENT
    type: "STANDARD" // Allowed types are STANDARD and CONTROL
  };

  const metadata = {
    "OtherUsers": [`${receiver.messagingId}+${receiver.role}`],
    "Sender":`${sender.messagingId}+${sender.role}`,
  }

  await axiosInstance.post(`/channel/send-message`, JSON.stringify(params), { headers : await getStoredMessagingAuth()})
  .then(response => {
    sentMessage = {
      response: response,
      CreatedTimeStamp: new Date(),
      Sender: {
        Arn: sender.externalUserId,
        Id: sender.messagingId
      }
    }
    let messageId = response?.data?.MessageId ? response.data.MessageId : ""
    publishMessagingEvent("messageSent", sender.messagingId, channelArn, messageId, "", JSON.stringify(metadata), true)
  })
  .catch(error => {
    console.error('Error sending message:', error)
  });

  return sentMessage;
}

/* update channel markers */
export const updateChannelMarkerBackend = async(
  channelArn: string | null,
  messagingUserId: string,
  adminId: string,
  marker:string,
  isNull:any
) => {
  const params = {
    channelArn,
    messagingUserId,
    adminId,
    marker,
    isNull
  }
  if(channelArn) {
    await axiosInstance.post(`/channel/update-marker`,
      JSON.stringify(params), { headers : await getStoredMessagingAuth()}).then(() => {
        let eventName = marker == MessagingConstants.END_CONVERSATION_MARKER ? "channelDisabled" : "channelReenabled"
        publishMessagingEvent(eventName, messagingUserId, channelArn, "", "", "", null)
        updateReadMarker(messagingUserId, channelArn, true).then(() => {
          return true
        })
        return false
      }).catch( () => {
        return false
    })
  } return false
}

export function formatDate(timestamp: number, timezone: string | null) {
  const date = new Date(timestamp);
  const defaultTimeZone = 'America/Detroit';
  const timeZone = timezone ? timezone : defaultTimeZone
  const options: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: timeZone,
    timeZoneName: 'short'
  };
  const formatter = new Intl.DateTimeFormat(undefined, options);
  const parts = formatter.formatToParts(date);

  let month, day, year, hour, minute, ampm;

  parts.forEach(({ type, value }) => {
    switch(type) {
      case 'month':
        month = value;
        break;
      case 'day':
        day = value;
        break;
      case 'year':
        year = value;
        break;
      case 'hour':
        hour = value;
        break;
      case 'minute':
        minute = value;
        break;
      case 'dayPeriod':
        ampm = value;
        break;
    }
  });
  return `${month}/${day}/${year} ${hour}:${minute} ${ampm}`;
}


export const removeFromScreen = async(
  channelArn: string,
  content: string,
  messageId:string,
  messagingUserId: string,
  updateFlag: string) => {
    if(!channelArn || !messageId) return false
    const params = {
      "adminId": messagingUserId,
      "messagingUserId": messagingUserId,
      "updateFlag": updateFlag,
      "channelArn": channelArn,
      "content": content,
      "messageId": messageId
    }
    await axiosInstance.post(`/channel/update-message`, JSON.stringify(params), { headers : await getStoredMessagingAuth()}).then(res => {
      let eventName = updateFlag == MessagingConstants.REMOVED_FROM_SCREEN_TIMESTAMP ? "messageDelete" : "messageUndeleted"
      publishMessagingEvent(eventName, messagingUserId, channelArn, messageId, "", "", null)
      return true
    }).catch(err => {
      return false
    })
}

export const deleteChannelMessage = async(
  channelArn: string,
  messageId:string,
  userId: string) => {
  if(!channelArn || !messageId) return false
  const params = {
    "adminId": userId,
    "messagingUserId": userId,
    "channelArn": channelArn,
    "messageId": messageId
  }
  await axiosInstance.post(`/channel/redact-message`, JSON.stringify(params), { headers : await getStoredMessagingAuth()}).then(res => {
    publishMessagingEvent("messageRedacted", userId, channelArn, messageId, "", "", null)
    return true
  }).catch(err => {
    return false
  })
}

export const updateTermsOfServiceAgreed = async (userId: string) => {
  const params = { "messagingUserId": userId }
  await axiosInstance.post(`/user/agree-terms-of-service`, JSON.stringify(params), { headers : await getStoredMessagingAuth()}).then(()=> {
    publishMessagingEvent("agreeTermsOfService", userId, "", "", "", "", null)
    return true
  }).catch(err => {
    throw err
  })
}

export const createChannel = async (
  channelName: string,
  // jobId: string | null, // remove null
  job: {
    date_applied?: string | null,
    job_id: string | null,
    candidate_id: string | null,
  },
  receiver: {
    messagingId: string,
    externalUserId: string,
    role: string
  },
  sender : {
    externalUserId: string,
    messagingId: string,
    role: string
  }
) => {
  const metadata = {
    "ChannelType": "PUBLIC_STANDARD",
    "Members": [`${sender.externalUserId}-${sender.role}`, `${receiver.externalUserId}-${receiver.role}`],
    "Admin": sender.messagingId,
    "OtherUsers": [receiver.messagingId],
    "JobId": job.job_id,
    "CandidateId": job.candidate_id,
    "Cadences":{"jobseeker":3,"recruiter":4},
    "EndConversationTimestamp": null,
    "EndOrReenableActionDoer": null,
    "ReEnableConversationTimestamp": null,
    "DateApplied": job.date_applied
  }
  const params = {
    "messagingUserId": sender.messagingId,
    "channelName": channelName,
    "mode": "RESTRICTED",
    "privacy": "PUBLIC",
    "metadata": JSON.stringify(metadata)
  }

  return await axiosInstance.post(`/channel/create`, JSON.stringify(params), { headers : await getStoredMessagingAuth()}).then((res)=> {
    let channelArn = res?.data?.ChannelArn ? res.data.ChannelArn : ""
    // publishMessagingEvent("channelCreated", sender.messagingId, channelArn, "", "", JSON.stringify(metadata), null)
    return { channelArn, metadata }
  }).catch(err => {
    throw err
  })
}

export const createUserArn = async (
 user: any
) => {
  const params = {
    "externalSystemUserId": user.externalUserId,
    "firstName": user.info.first_name,
    "lastName": user.info.last_name,
    "email": user.info.email,
    "mobilePhoneNumber": user.info.mobile,
    "role": user.role
  }
  return await axiosInstance.post(`/user/create`, JSON.stringify(params), { headers : await getStoredMessagingAuth()}).then((res)=> {
    let messagingUserId = res?.data?.MessagingUserId ?  res?.data?.MessagingUserId : null
    publishMessagingEvent("userCreated", messagingUserId, "", "", "", "", null)
    return messagingUserId
  }).catch(err => {
    throw err
  })
}

export const createChannelMembership = async (
  messagingUserId: string,
  channelArn: string,
) => {
  const params = {
    "messagingUserId": messagingUserId,
    "channelArn": channelArn
  }
  return await axiosInstance.post(`/channel/create-membership`, JSON.stringify(params), { headers : await getStoredMessagingAuth()}).then(()=> {
    publishMessagingEvent("memberAdded", messagingUserId, channelArn, "", "", "", null)
    return true
  }).catch(err => {
    throw err
  })
}

export const updateReadMarker = async (
  messagingUserId: string,
  channelArn: string,
  isSelf: boolean
) => {
  const params = {
    "messagingUserId": messagingUserId,
    "channelArn": channelArn
  }
  return await axiosInstance.post(`/channel/read-marker`, JSON.stringify(params), { headers : await getStoredMessagingAuth()}).then(()=> {
    publishMessagingEvent("readMarkerSet", messagingUserId, channelArn, "", "", "", isSelf)
    return true
  }).catch(err => {
    throw err
  })
}

export const decryptData = async (str: string) => {
  return Buffer.from(str, 'base64').toString('binary')
}
export const encryptData = async (str: string) => {
  return Buffer.from(str, 'binary').toString('base64')
}

export const publishMessagingEvent = async (
  eventName : string | null,
  messagingUserId : string | null,
  channelArn : string | null,
  messageId : string | null,
  applicantGuid : string | null,
  metadata : string | null,
  isSelf: boolean | null
) => {
  const params = {
    "eventName" : eventName,
    "app" : isAndroid ? "android" : ( isIOS ? "iOS" : "Web" ),
    "eventDateTime" : new Date().toISOString(),
    "messagingUserId" : messagingUserId,
    "channelArn" : channelArn,
    "messageId" : messageId,
    "osVersion" : osVersion,
    "applicantGuid" : applicantGuid,
    "metadata" : metadata,
    "isSelf": isSelf
  }
  return await axiosInstance.post(`/publish/message/event`, JSON.stringify(params), { headers : await getStoredMessagingAuth()})
  .then(() => {
    return true
  }).catch(err => {
    return err
  })
}

export const getStoredMessagingAuth = () => {
  return {
    'Content-Type': 'application/json',
    'Authorization': localStorage.getItem(`${process.env.REACT_APP_ENV}-messaging`)
  }
}

export const validateToken = async (messagingAuthKey: string) => {
  if (messagingAuthKey && messagingAuthKey.trim() !== '') {
    const validateTokenParams = {
      "token": messagingAuthKey,
      "environment": `${process.env.REACT_APP_ENV}`
    }
    const validatedToken = await axiosInstance.post(
      `/security/validate-token`,
      JSON.stringify(validateTokenParams),
      { headers: {'Content-Type': 'application/json'} }
    )
    if(validatedToken?.data?.valid) {
      return getStoredMessagingAuth()
    } else {
      return await requestMessagingHeader()
    }
  } else {
    return await requestMessagingHeader()
  }
}

export const requestMessagingHeader = async () => {
  try {
    const response = await axiosInstance.get(`/fetch/messaging/auth`)
    if(response?.data) {
      const messagingAuth = await decryptData(response?.data?.response)
      let header = {
        'Content-Type': 'application/json',
        'Authorization': messagingAuth
      }
      localStorage.setItem(`${process.env.REACT_APP_ENV}-messaging`, messagingAuth)
      return header;
    } else {
      return {}
    }
  } catch (error) {
    console.log('Error getting auth key')
    return {}
  }
}

// Push Notification
export const sendPushNotification = async (
  messagingUserId: string | null,
  targetArn: string | null,
  gcm: string | null
) => {
  const params = {
    "messagingUserId": messagingUserId,
    "targetArn": targetArn,
    "message": {
      "default": "default",
      "GCM": gcm
    }
  }
   return await axiosInstance.post(`/push-notification/publish`, params, { headers : await getStoredMessagingAuth()}).then(res => {
    if (res && res.data) {
      return res.data
    }
    return null
  }).catch( err => {
    return err
  })
}

export const checkIsLastMessageSender = (messageList:any, loggedInUserArn: string) => {
  if(messageList && messageList.length > 0) {
    const lastMessage = messageList[messageList.length - 1]
    if (lastMessage && lastMessage.Sender && splitUserArn(lastMessage.Sender.Arn) === loggedInUserArn) {
      return true
    } else {
      return false
    }
  }
  return false
}

export const updateUserTimezone = async (messagingUserId: string, timezone: string) => {
  const params = {
    messagingUserId,
    timezone
  }
  return await axiosInstance.post(`/user/update-timezone`, JSON.stringify(params), { headers : await getStoredMessagingAuth()}).then(res => {
  })
}

export const truncateText = (text: string, maxLen: number) => {
  return (text.length > maxLen) ? text.slice(0, maxLen) + '...' : text
}

export const validateQuickConnectToken = async (token: string) => {
  try {
    const messagingHeader = await validateToken(STORED_MESSAGING_AUTH_KEY)
    return axiosInstance.get(`/quick-connect/validate/${token}`,{
      headers: messagingHeader
    }).then(res => {
      const status = res?.data?.status
      const message = res?.data?.message
      if(status == "failed") {
        return (message == "Access token has expired") ? "expired" : "invalid"
      } else {
        return res
      }
    })
  } catch (error) {
    console.log('Error validating quick connect token')
    return null
  }
}

export const requestNewQuickConnectAccess = async (token: string) => {
  try {
    const messagingHeader = await validateToken(STORED_MESSAGING_AUTH_KEY)
    return axiosInstance.get(`/quick-connect/renew/${token}`,{
      headers: messagingHeader
    }).then(res => {
        if(res?.status == 200 && res?.data?.status === 'success') {
          return true
        }
        return false
    }).catch(err => {
      console.log('Error renewing quick connect token')
      return false
    })
  } catch (error) {
    console.log('Error renewing quick connect token')
    return false
  }
}

export const getChannelDetails = async (
  channelArn: string,
  messagingUserId: string
) => {
  const params = {
    channelArn,
    messagingUserId
  }
  const messagingHeader = await validateToken(STORED_MESSAGING_AUTH_KEY)

   return await axiosInstance.post(`/channel/details`, JSON.stringify(params),
    { headers : messagingHeader }
   ).then(res => {
      if (res && res.data) {
        return res.data
      }
    return null
  }).catch( err => {
    return err
  })
}

export const getChannelMembershipDetails = async (
  channelArn: string | null,
  messagingUserId: string
  ) => {
  if(channelArn && messagingUserId) {
    const params = {
      channelArn,
      messagingUserId
    }
    const messagingHeader = await validateToken(STORED_MESSAGING_AUTH_KEY)

    return await axiosInstance.post(`/channel/membership-details`, JSON.stringify(params),
      { headers : messagingHeader }
    ).then(res => {
        if (res && res.data) {
          return res.data
        }
      return null
    }).catch( err => {
      return err
    })
  } else {
    return null
  }
}


export const getJobDetails = async (jobId: string) => {
  try {
   requestConnectionHeader().then((res) =>{
   })
    const response = await jobseekerAxiosInstance.get(`/get/job/info?jobId=${jobId}`,
      { headers: {
        'Content-Type': 'application/json',
        'Authorization': await requestConnectionHeader()
      }})
    return response?.data?.data
  } catch (error) {
    console.log('Error getting job details', error)
    return null
  }
}

// Format the location data into a string
const formatLocation = (loc: any) => {
  if (!loc) return '';
  if (Array.isArray(loc)) {
    return loc.map(location => {
      const parts = [location.city, location.region, location.country].filter(Boolean);
      return parts.join(', ');
    }).join(' | ');
  } else {
    const parts = [loc.city, loc.region, loc.country].filter(Boolean);
    return parts.join(', ');
  }
};

export const formQuickConnectChatData = async (quickConnectData: any) => {
  let chatData = {}
  if(quickConnectData) {
    const channelDetails = await getChannelDetails(quickConnectData.channel, quickConnectData.sender_id)
    const jobData = await getJobDetails(quickConnectData.job_id)
    if(!jobData || !channelDetails) return null

    // Format the job data
    const job = {
      ...jobData,
      locations: formatLocation(jobData.locations)
    };

    chatData = {
      token: null,
      receiver: {
        external_user_id: null,
        first_name: quickConnectData.sender_fname,
        last_name: quickConnectData.sender_lname,
        messaging_id: quickConnectData.sender_id,
        role: "Recruiter"
      },
      sender: {
        external_user_id: null,
        first_name: "",
        last_name: "",
        messaging_id: quickConnectData.receiver_id,
        role: "Jobseeker"
      },
      channel: channelDetails?.Channel,
      connections: {
        isBlocked: false,
        isBlockedLink: false
      },
      job
    }
    return chatData
  } else {
    console.log('Error: Invalid quick connect token')
    return null
  }
}

export const setAwsSession = async (userArn: string) => {
  try {
    axiosInstance.get(`/cognito/get/token`,{
      headers: await getStoredMessagingAuth()
    }).then(res => {
      if(res.status === 200) {
        const tempToken = res.data?.token
        decryptData(tempToken).then(async res => {
          const creds = JSON.parse(res)
          AWS.config.region = keys.AWS_REGION
          AWS.config.update({
            accessKeyId: creds?.code,
            secretAccessKey: creds.user,
            sessionToken: creds.token
          })
          messagingService.connect(userArn)
      })
      } else {
        console.log('AWS Error in aws keys: Retrying...')
        setTimeout(setAwsSession, 30 * 1000)
      }
    })
  } catch(error) {
    console.log('Cognito error: Retrying...', error)
    setTimeout(setAwsSession, 30 * 1000)
  }
}

const requestConnectionHeader = async () => {
  try {
    const connectionToken = await jobseekerAxiosInstance.get(`/get/conn/token`)
    const decryptedToken = await decryptData(connectionToken?.data?.token)
    return decryptedToken
  } catch (error) {
    console.log('Error getting connections key', error)
    return ''
  }
}

export const formatDateTime = (isoString: string | number | Date) => {
  const date = new Date(isoString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear().toString().slice(-2);
  const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
  return `${formattedTime} - ${formattedDate}`;
}
