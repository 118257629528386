import React, { useEffect, useState } from "react";
import theme from "../../theme";
import { useStyles } from "../../style/styles";
import { Box, Button, CircularProgress, Grid, ThemeProvider, Typography, useMediaQuery } from "@mui/material";
import JobDescription from "../../components/JobDescription";
import * as utils from '../../lib/messageUtils'
import Chat from "../../components-refactored/Chat";

import store from '../../redux/store'
import { Provider } from 'react-redux'
import InvalidAccess from "../../components-refactored/InvalidAccess";
import Header from "../../components-refactored/Header";
import Footer from "../../components-refactored/Footer";
import TermsOfUse from "../../components-refactored/TermsOfUse";
import HomePage from "../../components-refactored/HomePage";

const QuickConnect = () => {
  const classes = useStyles();
  const url = window.location.href;
  const urlObj = new URL(url);
  const platform = urlObj.searchParams.get("platform") || "web";
  const deviceType =  urlObj.searchParams.get("device") || "web";
  const token = urlObj.searchParams.get("token");
  const isMobile = useMediaQuery("(max-width: 700px)") || platform === "mobile";
  const [apply_date, setApplyDate] = useState<string | null>("")
  const [quickConnectData, setQuickConnectData] = useState<any | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isInvalidToken, setIsInvalidToken] = useState<boolean>(false)
  const [isExpiredToken, setIsExpiredToken] = useState<boolean>(false)
  const [termsAgreed, setTermsAgreed] = useState(true)
  const [termsDeclined, setTermsDeclined] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    if(token) {
      utils.validateQuickConnectToken(token).then((res: any) => {
        if(res && res?.data && res !== 'expired' && res !== 'invalid') {
          const returnedQuickConnectData = res?.data
          setApplyDate(returnedQuickConnectData?.data?.date_applied || "N/A")
          utils.formQuickConnectChatData(returnedQuickConnectData?.data).then((chatData: any) => {
            setQuickConnectData(chatData)
            if(chatData?.sender?.messaging_id) {
              utils.getUserInstanceArnv2(null, null, chatData?.sender?.messaging_id).then((fetchedUser) => {
                setTermsAgreed(fetchedUser?.TermsOfServiceAccepted)
              })
            }
            setIsLoading(false)
         })
        }
        else {
          setIsLoading(false)
          if(res === 'expired') {
            setIsExpiredToken(true)
          } else {
            setIsInvalidToken(true)
          }
        }
      })
    }
  }, [token])

  const closeQuickConnect = () => {
    setTermsAgreed(false)
    setTermsDeclined(true)
  }

  const onContinue = () => {
    setTermsDeclined(false)
    setTermsAgreed(false)
  }

  const agreeToTerms = () => {
    utils.updateTermsOfServiceAgreed(quickConnectData.sender?.messaging_id).then(() => {
      setTermsAgreed(true);
    })
  }

  return (
    <>
      <Provider store={store}>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"/>
          <ThemeProvider theme={theme}>
            { !isLoading ? (
              quickConnectData && !isInvalidToken && !isExpiredToken ? (
                !termsDeclined ? (
                  <div className={ isMobile ? `${classes.quickConnectMainContainerMobile}` : `${classes.quickConnectMainContainer}`}>
                    {/* HEADER */}
                    <Header/>
                    {/* QUICK CONNECT BODY */}
                    <main className={ isMobile ? `${classes.quickConnectBodyMobile}` : `${classes.quickConnectBody}`}>
                      {/* CHAT / MESSAGING COMPONENT */}
                      <div className={ isMobile ? `${classes.quickConnectChatMobile}` : `${classes.quickConnectChat}`}>
                        <Chat
                          sender={quickConnectData.sender}
                          receiver={quickConnectData.receiver}
                          channel={quickConnectData.channel}
                          job={quickConnectData?.job}
                          token={quickConnectData.token}
                          connections={quickConnectData.connections}
                          platform={platform}
                          deviceType={deviceType}
                          apply_date={apply_date}
                        />
                      </div>
                      {/* JOB DESCRIPTION */}
                      {!isMobile && (
                        <div className={classes.quickConnectJobDescription}>
                          <JobDescription
                            platform = "web"
                            apply_date={apply_date}
                            job = {quickConnectData?.job}
                          />
                        </div>
                      )}
                    </main>
                    <div>
                {/*  TERMS OF USE */}
                <TermsOfUse
                  open={!termsAgreed}
                  onClose={closeQuickConnect}
                  onAccept={agreeToTerms}
                />
                </div>
                  {!isMobile && (
                    <Footer/>
                  )}
                </div>
              ) : (
                // DECLINED TERMS OF USE
                <HomePage onContinue={onContinue}/>
              )
            ):
            <>
              { isExpiredToken ? (
                // EXPIRED ACCESS
                <InvalidAccess accessError="expired" />
              ) : (
                // INVALID ACCESS
                <InvalidAccess accessError="invalid" />
              )}
            </>
          ) : (
            // LOADING SPINNER
            <div style={{background: 'white'}}>
              <div className={classes.centeredStyle}>
                <Box className={classes.centeredSpinner}>
                  <CircularProgress size={40} thickness={5} color="primary" />
                </Box>
              </div>
            </div>
          )}
        </ThemeProvider>
      </Provider>
    </>
  )
};

export default QuickConnect;
