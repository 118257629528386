
import { useStyles } from "../../style/styles";

const Header = () => {
  const classes = useStyles();
    return (
        <header className={classes.quickConnectHeader}>
            <div style={{padding: '16px 24px'}}>
                <img
                    src="/images/jt-logo.svg"
                    alt="jt-header"
                    width={150}
                />
            </div>
          </header>
    )
}

export default Header;