
import './App.css'
import AWS from 'aws-sdk'
import store from './redux/store'
import { Provider } from 'react-redux'
import { useEffect, useState } from 'react'
import * as utils from './lib/messageUtils'
import * as keys from './config/awsChimeConfig'
import { Channel } from './components/Chat/types'
import { MessagingConstants } from './lib/enums'
import messagingService from './services/MessagingService'
import Chat from './components/Chat'
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material'
import MobileChat from './components/MobileChat'
import axiosInstances, { axiosInstance } from './config/axios'
import MinimizedComponent from './components/MinimizedChat'
import { useStyles } from './style/styles'

const App = () => {
  const classes = useStyles()
  const { axiosInstance } = axiosInstances
  const url = window.location.href
  const searchParams = window.location.search
  const tokenMatch = searchParams?.match(/[?&]token=([^&]+)/);
  const platformMatch = searchParams?.match(/[?&]platform=([^&]+)/);
  const token = tokenMatch ? decodeURIComponent(tokenMatch[1]) : ''
  const [sender, setSender] = useState<any | null>(null)
  const [receiver, setReceiver] = useState<any | null>(null)
  const [job, setJob] = useState<any | null>(null)
  const [fetchedChannel, setFetchedChannel] = useState<any | null>(null)
  const [connections, setConnections] = useState<any>({isBlocked: false, isBlockedLink: false})
  const [channel, setChannel] = useState<Channel>({ channelName: '', channelArn: null, metadata: null, isEndedConversation: false })
  const [isLoading, setIsLoading] = useState(true)
  const [platform, setPlatform] = useState<string>('')
  const [minimized, setIsMinimized] = useState<boolean>(false)
  const [unreadCount, setUnreadCount] = useState(0);
  const [messagingType, setMessagingType] = useState<string>(MessagingConstants.MESSAGING_CHAT)

  useEffect(() => {
    const urlObj = new URL(url);
    const path = urlObj.pathname;

    if (path.includes('chat')) {
      setMessagingType(MessagingConstants.MESSAGING_CHAT)
    } else if (path.includes('quick-connect')) {
      setMessagingType(MessagingConstants.MESSAGING_QUICK_CONNECT)
    } else {
        console.log('Unknown URL type');
    }
  }, [url])

  useEffect(() => {
    setMessagingData()
  }, [])

  const setMessagingData = async () => {
    setPlatform(platformMatch ? platformMatch[1] : '');
    await utils.decryptData(token).then(res => {
      const { sender, receiver, job, connections, channel } = JSON.parse(res)
      if(connections) setConnections(connections)
      setJob(job)
      setSender(sender)
      setReceiver(receiver)
      setFetchedChannel(channel)
      fetchLoggedInUser(sender, receiver).then(() =>{
        getChannelMembership(sender, receiver, job.job_id, channel).then(() => {
          setIsLoading(false)
        })
      })
    }).catch(err =>{
      throw err
    })
  }

  useEffect(() =>{
    if(sender && sender.userArn && sender.userArn !== '') {
      setAwsSession()
      /* STS expiry temp: 15 mins (buffer: 5 mins) */
      const tokenExpiryinMs = 10 * 60 * 1000
      const interval = setInterval(() => {
          setAwsSession();
      }, tokenExpiryinMs);
      return () => clearInterval(interval);
    }

  }, [sender, sender?.userArn])

  const setAwsSession = async () => {
    try {
      axiosInstance.get(`/cognito/get/token`,{
        headers: await utils.getStoredMessagingAuth()
      }).then(res => {
        if(res.status === 200) {
          const tempToken = res.data?.token
          utils.decryptData(tempToken).then(async res => {
            const creds = JSON.parse(res)
            AWS.config.region = keys.AWS_REGION
            AWS.config.update({
              accessKeyId: creds?.code,
              secretAccessKey: creds.user,
              sessionToken: creds.token
            })
            messagingService.connect(sender.userArn)
        })
        } else {
          console.log('AWS ERROR in aws keys: Retrying...')
          setTimeout(setAwsSession, 30 * 1000)
        }
      })
    } catch(error) {
      console.log('Cognito error: Retrying...', error)
      setTimeout(setAwsSession, 30 * 1000)
    }
  }

  const fetchLoggedInUser = async (sender: any, receiver: any) => {
    try {
      const fetchedSender = await utils.getUserInstanceArnv2(sender.external_user_id, sender.role, null)
      sender.email = fetchedSender.hasOwnProperty('Email') ?  fetchedSender.Email : null
      sender.first_name = fetchedSender.hasOwnProperty('FirstName') ?  fetchedSender.FirstName : null
      sender.last_name = fetchedSender.hasOwnProperty('LastName') ?  fetchedSender.LastName : null
      sender.userArn = fetchedSender.hasOwnProperty('MessagingUserId') ?  fetchedSender.MessagingUserId : null
      sender.termsAgreed = fetchedSender.hasOwnProperty('TermsOfServiceAccepted') ? fetchedSender.TermsOfServiceAccepted : true
      sender.mobile = fetchedSender.hasOwnProperty('MobilePhoneNumber') ? fetchedSender.MobilePhoneNumber : null
      sender.timezone = fetchedSender.hasOwnProperty('Timezone') ? fetchedSender.Timezone : null // change to EST

      const fetchedReceiver = await utils.getUserInstanceArnv2(receiver.external_user_id, receiver.role, null)
      receiver.email = fetchedReceiver.hasOwnProperty('Email') ?  fetchedReceiver.Email : receiver?.info?.email
      receiver.first_name = fetchedReceiver.hasOwnProperty('FirstName') ?  fetchedReceiver.FirstName : receiver?.info?.first_name
      receiver.last_name = fetchedReceiver.hasOwnProperty('LastName') ?  fetchedReceiver.LastName : receiver?.info?.last_name
      receiver.userArn = fetchedReceiver.hasOwnProperty('MessagingUserId') ?  fetchedReceiver.MessagingUserId : null
      receiver.termsAgreed = fetchedReceiver.hasOwnProperty('TermsOfServiceAccepted') ? fetchedReceiver.TermsOfServiceAccepted : false
      receiver.mobile = receiver?.info?.mobile_phone_number || ""
      // receiver.mobile = fetchedReceiver.hasOwnProperty('MobilePhoneNumber') && fetchedReceiver.MobilePhoneNumber ? fetchedReceiver.MobilePhoneNumber : receiver?.info?.mobile_phone_number
    } catch (error) {
      console.error(error)
    }
  }

  const getChannelMembership = async (sender: any, receiver: any, job_id: string, channel: any) => {
    if(channel?.channelArn && sender.userArn) {
      const channelDetails = await utils.getChannelMembershipDetails(channel.channelArn, sender.userArn)
      const returnedChannel = channelDetails?.ChannelMembership
      if(returnedChannel) {
        const channelMetadata = JSON.parse(returnedChannel?.ChannelSummary?.Metadata)
        const adminUserArn = sender.role.toUpperCase() === MessagingConstants.RECRUITER ? sender.userArn : receiver.userArn
        const endConversationTimestamp = channelMetadata?.EndConversationTimestamp || null
        const isEndedConversation = endConversationTimestamp ? true : false
        setChannel( prevChannel => ({ ...prevChannel,
                                          channelArn: returnedChannel?.ChannelSummary?.ChannelArn || null,
                                          channelName: returnedChannel?.ChannelSummary?.Name || null,
                                          metadata: returnedChannel?.ChannelSummary.Metadata,
                                          isEndedConversation: isEndedConversation }))
      } else {
        setChannel( prevChannel => ({ ...prevChannel,
                                        channelArn: null,
                                        channelName: `${sender.external_user_id}-${receiver.external_user_id}-${job_id}`,
                                        metadata: null,
                                        isEndedConversation: false }))
      }
    } else {
      setChannel( prevChannel => ({ ...prevChannel,
        channelArn: null,
        channelName: `${sender.external_user_id}-${receiver.external_user_id}-${job_id}`,
        metadata: null,
        isEndedConversation: false }))
    }
    
  }


  const onChatDisplayChange = () => {
    if(!minimized) {                                                 /*TJSKR-1852*/
      window.parent.postMessage({ action: 'MESSAGING_RESIZE_IFRAME', specificAction: 'MINIMIZE', width: '300px', token, candidateId: job?.candidate_id }, '*');
    } else {
      window.parent.postMessage({ action: 'MESSAGING_RESIZE_IFRAME', specificAction: 'MAXIMIZE', width: '450px', token, candidateId: job?.candidate_id }, '*');
    }
    setIsMinimized(!minimized)
  }

  /* TJSKR-626 Listener for changes in update count from parent component (CM) */
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      /*
        // Ensure that the message comes from the expected origin (localhost:3000)
        if (event.origin !== 'http://localhost:3000') {
          return;
        }
      */
      if (event.data.type == "MESSAGING_UPDATE_UNREAD") {
        setUnreadCount(event.data.unreadCount);
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <Provider store={store}>
      {sender && receiver && channel && !isLoading ? (
          platform !== 'mobile' ? (
            minimized ? (
              <MinimizedComponent
                channel={channel}
                receiver={receiver}
                token={token}
                unreadCount={unreadCount}
                onMinimizeChat={onChatDisplayChange}
                job={job}
              />
            ) : (
              <div className="App">
                <Chat
                  sender={sender}
                  receiver={receiver}
                  channel={channel}
                  job={job}
                  token={token}
                  connections={connections}
                  onMinimizeChat={onChatDisplayChange}
                />
              </div>
            )
          ) : (
            <MobileChat
              sender={sender}
              receiver={receiver}
              channel={channel}
              token = {token}
              job={job}
              connections={connections}
              onMinimizeChat={onChatDisplayChange}
            />
          )
      ) : (
        <div className="App">
          <Box className={classes.centeredSpinner}>
            <CircularProgress size={40} thickness={5} color="primary" />
          </Box>
        </div>
      )}
    </Provider>
  )
}

export default App
