import { Container, Typography, Button, Link, Box, ThemeProvider, useMediaQuery } from '@mui/material'
import theme from "../../theme"
import { IProps } from './types'
import { useEffect, useState } from 'react';
import { MessagingConstants } from '../../lib/enums';
import { requestNewQuickConnectAccess } from '../../lib/messageUtils';
import Footer from '../Footer';

const InvalidAccess = (props: IProps) => {
    const { accessError } = props
    const url = window.location.href;
    const urlObj = new URL(url);
    const token =  urlObj.searchParams.get("token") || ""
    const deviceType =  urlObj.searchParams.get("device") || "web";
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); /* Check if it's a mobile view */
    const isSmallHeight = useMediaQuery('(max-height: 700px)');
    const logoWidth = isSmallHeight ? 160 : (isMobile ? 200 : 280.5);
    const logoHeight = isSmallHeight ? 33 : (isMobile ? 41.5 : 58);
    const imageWidth = isSmallHeight ? '200px' : (isMobile ? '90%' : '280px');
    const fontSize = isSmallHeight ? '18px' : '24px';
    const [mainErrorMsg, setMainErrorMsg] = useState<string>("")
    const [subText, setSubText] = useState<string>("")
    const [showInitialRequest, setShowInitialRequest] = useState<boolean>(true)
    const [hasError, setHasError] = useState<boolean>(false)
    const [emailAccessText, setEmailAccessText] = useState<string>("Send me a new access e-mail")
    const appLink = deviceType === 'web-ios' ? MessagingConstants.IOS_JS_APP_LINK
                  : deviceType === 'web-android' ? MessagingConstants.ANDROID_JS_APP_LINK
                  : MessagingConstants.WEB_JS_APP_LINK;

    useEffect(() => {
        if(accessError === "expired") {
            setMainErrorMsg('Your access link has expired')
            setSubText('To access your messages,')
        } else {
            setMainErrorMsg('Your access link is invalid')
            setSubText('Unable to access this link')
        }
    }, [accessError])

    const requestNewAccess = () => {
        setEmailAccessText("Sending...")
        if(token.trim() === '') {
            setShowInitialRequest(false)
            setHasError(true)
            setEmailAccessText("Invalid token. Request access failed")
        }
        else {
            requestNewQuickConnectAccess(token).then((res) => {
                if(res) {
                    setShowInitialRequest(false)
                    setHasError(false)
                    setEmailAccessText("New access successfully sent to email")
                } else {
                    setShowInitialRequest(true)
                    setHasError(true)
                    setEmailAccessText("New access request failed. Try again?")
                }
            })
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"/>
            <Box sx={{
                minHeight: `${!isMobile ? 'calc(100vh - 70px)' : deviceType === 'web-ios' ? `calc(100vh - 20px)` : '100vh' }`,
                maxHeight: `${!isMobile ? 'calc(100vh - 70px)' : deviceType === 'web-ios' ? `calc(100vh - 20px)` : '100vh' }`,
                height: !isMobile ? 'calc(100vh - 70px)' : '100vh',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
                overflow: 'auto'
            }}>
                <Container sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: isSmallHeight ? '12px' : (isMobile ? '24px 16px' : '24px'),
                    paddingTop: isMobile ? (isSmallHeight ? '40px' : '60px') : isSmallHeight ? '12px' : '24px',
                    paddingBottom: isMobile ? (isSmallHeight ? '40px' : '60px') : isSmallHeight ? '12px' : '24px',
                    textAlign: 'center',
                    flex: 1,
                    width: '100%',
                    maxWidth: '100% !important',
                    boxSizing: 'border-box',
                    margin: 0,
                    minHeight: isMobile ? 'auto' : '100%'
                }}>
                <img
                    src={"/images/jt-logo.svg"}
                    alt="Jobtarget"
                    width={logoWidth}
                    height={logoHeight}
                    style={{ maxWidth: '100%', height: 'auto' }}
                />
                <img
                    src={"/images/no_search.svg"}
                    alt="No-Search"
                    style={{
                        width: imageWidth,
                        height: 'auto',
                        maxWidth: isMobile ? '80%' : '100%',
                        margin: isSmallHeight ? '16px 0' : '24px 0'
                    }}
                />

                <Typography
                    align="center"
                    sx={{
                        fontWeight: 500,
                        fontSize: fontSize,
                        margin: isSmallHeight ? '12px 0' : '16px 0',
                        maxWidth: isMobile ? '85%' : '90%'
                    }}
                >
                    { mainErrorMsg }
                </Typography>
                {/* /* PUT IN ONE LINE // */}
                <Typography variant="body1" align="center" paragraph sx={{ 
                    color: '#666', 
                    padding: '0 16px', 
                    margin: isSmallHeight ? '8px 0 12px' : '16px 0 24px', 
                    maxWidth: isMobile ? '85%' : '90%', 
                    fontSize: isSmallHeight ? '14px' : (isMobile ? '15px' : 'inherit'),
                    whiteSpace: isMobile ? 'normal' : 'pre',
                    display: isMobile ? 'block' : 'inline-flex',
                    justifyContent: 'center', 
                    alignItems: 'center',
                    wordBreak: isMobile ? 'break-word' : 'normal'
                }}>
                   { subText } { accessError === 'expired' ? <><a href={appLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', marginLeft: '4px', marginRight: '4px' }}><Typography sx={{ color: 'primary.main', cursor: 'pointer', display: 'inline' }}>register with JobTarget Jobseeker</Typography></a> or </> : '' }
                </Typography>

                { accessError == "expired" && (
                  showInitialRequest ? (
                    <>
                    <a href={appLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <Typography sx={{
                            color: hasError ? '#E97451' : 'primary.main',
                            cursor: 'pointer',
                            fontSize: isSmallHeight ? '14px' : 'inherit'
                        }}>
                            {/* { emailAccessText} */}
                        </Typography>
                        </a>

                        <div onClick={requestNewAccess}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    // margin: isSmallHeight ? '16px 0' : '24px 0',
                                    fontSize: isSmallHeight ? '12px' : (isMobile ? '14px' : '16px'),
                                    fontFamily: 'Poppins',
                                    fontWeight: 300,
                                    textTransform: 'none',
                                    padding: isSmallHeight ? '6px 16px' : (isMobile ? '8px 16px' : '12px 24px'),
                                    // maxWidth: isMobile ? '85%' : '100%',
                                    whiteSpace: 'normal',
                                    height: 'auto',
                                    lineHeight: 1.5
                                }}>
                                { emailAccessText}
                            </Button>
                        </div>
                    </>

                    
                  ) : (
                    <div>
                        <Typography sx={{
                            color: hasError ? '#E97451' : 'primary.main',
                            fontSize: isSmallHeight ? '14px' : 'inherit'
                        }}>
                            { emailAccessText }
                        </Typography>
                    </div>
                  )
                )}

                { accessError !== "expired" && (
                    <a href={appLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                margin: isSmallHeight ? '16px 0' : '16px 0',
                                fontSize: isSmallHeight ? '12px' : (isMobile ? '14px' : '16px'),
                                fontFamily: 'Poppins',
                                fontWeight: 300,
                                textTransform: 'none',
                                padding: isSmallHeight ? '6px 16px' : (isMobile ? '8px 16px' : '8px 24px'),
                                maxWidth: isMobile ? '85%' : '90%',
                                whiteSpace: 'normal',
                                height: 'auto',
                                lineHeight: 1.5
                            }}>
                            Register with JobTarget Jobseeker
                        </Button>
                    </a>
                )}


                {/* { accessError == "expired" && (
                  showInitialRequest ? (
                    <div onClick={requestNewAccess}>
                        <Typography sx={{
                            color: hasError ? '#E97451' : 'primary.main',
                            cursor: 'pointer',
                            fontSize: isSmallHeight ? '14px' : 'inherit'
                        }}>
                            { emailAccessText}
                        </Typography>
                    </div>
                  ) : (
                    <div>
                        <Typography sx={{
                            color: hasError ? '#E97451' : 'primary.main',
                            fontSize: isSmallHeight ? '14px' : 'inherit'
                        }}>
                            { emailAccessText }
                        </Typography>
                    </div>
                  )
                )}
                <a href={appLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            margin: isSmallHeight ? '16px 0' : '24px 0',
                            fontSize: isSmallHeight ? '12px' : (isMobile ? '14px' : '16px'),
                            fontFamily: 'Poppins',
                            fontWeight: 300,
                            textTransform: 'none',
                            padding: isSmallHeight ? '6px 16px' : (isMobile ? '8px 16px' : '8px 24px'),
                            maxWidth: isMobile ? '85%' : '90%',
                            whiteSpace: 'normal',
                            height: 'auto',
                            lineHeight: 1.5
                        }}
                    >
                        Register with JobTarget Jobseeker
                    </Button>
                </a> */}
                {isMobile && (
                    <Footer isMobile={isMobile}/>
                )}
                </Container>
                {!isMobile && (
                    <Footer isMobile={isMobile}/>
                )}
            </Box>
        </ThemeProvider>
    );
};

export default InvalidAccess
