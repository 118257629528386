import { IProps } from "./types";
import { useStyles } from "../../style/styles";
import { Box, Typography, Link } from "@mui/material";

const Footer = (props: IProps) => {
  const { isMobile } = props
  const classes = useStyles();

  return (
    <footer className={ isMobile ? classes.quickConnectFooterMobile : classes.quickConnectFooter}>
      { !isMobile ? (
        <>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Link href="https://www.jobtarget.com/privacy-policy" target="_blank" sx={{ textDecoration: "none", color: "inherit" }}> Privacy Policy </Link>
            <Link  href="https://www.jobtarget.com/terms-of-use" target="_blank" sx={{ textDecoration: "none", color: "inherit" }}> Terms & Conditions </Link>
            <Link href="#" sx={{ textDecoration: "none", color: "inherit" }}> Cookie Preferences </Link>
          </Box>
          <Typography variant="body2" sx={{ marginLeft: "auto" }}> 2025 © JobTarget </Typography>
        </>
      ) : (
        <Box sx={{ height: 120, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 1 }}>
          <div style={{ display: 'flex', gap: '8px', fontSize: '14px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <div><Link href="https://www.jobtarget.com/privacy-policy" target="_blank" sx={{ textDecoration: "none", color: "inherit" }}>Privacy Policy</Link></div>
            <div><Link href="https://www.jobtarget.com/terms-of-use" target="_blank" sx={{ textDecoration: "none", color: "inherit" }}>Terms & Conditions</Link></div>
            <div><Link href="#" sx={{ textDecoration: "none", color: "inherit" }}>Cookie Preferences</Link></div>
            <div><Typography variant="body2" sx={{ marginLeft: "auto", color: 'black' }}>2025 © JobTarget</Typography></div>
          </div>
        </Box>
      )}
    </footer>
  );
};

export default Footer;
