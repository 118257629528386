import theme from '../../theme'
import { IProps, MessageResponse } from './types'
import Navbar from '../Navbar'
import ChatList from '../ChatList'
import ChatInput from '../ChatInput'
import React, { useEffect, useRef, useState } from 'react'
import { useStyles } from '../../style/styles'
import { Grid, ThemeProvider, Typography, useMediaQuery } from '@mui/material'
import * as utils from '../../lib/messageUtils'
import { useDispatch, useSelector } from 'react-redux'
import { ChannelData, ChannelDetails, ChannelInfo, RootState } from '../../redux/channel/types'
import { setChannelData,setChannelDetails,updateChannelMessageList,updateTermsAgreed } from '../../redux/channel/actions'
import messagingService from '../../services/MessagingService'
import { MessagingConstants } from '../../lib/enums'
import getDisplayName from '../../lib/getDisplayName'
import JobDescription from '../../components/JobDescription'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

const Chat = (props: IProps) => {
  const { sender, receiver, channel, job, connections, token, platform, apply_date, deviceType } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const loggedInUserArn = sender?.messaging_id
  const [isEndedConversation, setIsEndedConversation] = useState(false)
  const channelsReducer = useSelector((state: RootState) => state.channels.channels)
  const [channelData, setChannelReducer] = useState<ChannelData>()
  const [shouldLoadMessages, setShouldLoadMessages] = useState(false)
  const [selectedTimezone, setSelectedTimezone] = useState<string | null>(sender?.timezone)
  const [adminID, setAdminId] = useState<string>(loggedInUserArn)
  const [endOrReenableActionDoer, setEndOrReenableActionDoer] = useState<string | null>(null)
  const [updatedChannel, setUpdatedChannel] = useState<any>(channel)
  const [updatedConnections, setUpdatedConnections] = useState<any>(connections)
  const [showChat, setShowChat] = useState<boolean>(true)
  const [isJobDescriptionOpen, setIsJobDescriptionOpen] = useState(false)
  const isMobile = useMediaQuery("(max-width: 700px)") || platform === "mobile"

  useEffect(() => {
    if(channel) {
      mapChannelDetails()
      fetchChannel()
    }
    if(channel?.metadata){
      setAdminId(JSON.parse(channel?.Metadata).Admin)
    }
  }, [channel])

  useEffect(() => {
    setChannelReducer(channelsReducer[channel.Name])
    if (shouldLoadMessages) {
      handleLoadMessages()
      setShouldLoadMessages(false)
    }
  }, [channelsReducer, shouldLoadMessages])

  useEffect(() =>{
    if(sender) {
      utils.setAwsSession(sender?.messaging_id)
      /* STS expiry temp: 15 mins (buffer: 5 mins) */
      const tokenExpiryinMs = 10 * 60 * 1000
      const interval = setInterval(() => {
        utils.setAwsSession(sender?.messaging_id);
      }, tokenExpiryinMs);
      return () => clearInterval(interval);
    }
  }, [sender])
  
  useEffect(() => {
    messagingService.subscribeToMessageUpdate(callbackProcessor)
    return () => {
      messagingService.unsubscribeFromMessageUpdate(callbackProcessor)
    }
  }, [])

  const toggleJobDescription = () => {
    setIsJobDescriptionOpen(true);
  };

  const handleCloseJobDescription = () => {
    setIsJobDescriptionOpen(false);
  };

  const fetchChannel = async () => {
    let channelDetails = await utils.getChannelDetails(channel.ChannelArn, sender.messaging_id)
    channelDetails = channelDetails?.Channel
    if(channelDetails) {
      const metadata = JSON.parse(channelDetails?.Metadata)
      const isEndedConversation = metadata?.EndConversationTimestamp ? true : false
      setUpdatedChannel(channelDetails)
      setAdminId(metadata?.Admin)
      setIsEndedConversation(isEndedConversation)
      setEndOrReenableActionDoer(metadata?.EndOrReenableActionDoer)
      const blockedBy = metadata?.BlockedBy
      if (blockedBy && blockedBy.trim() !== '') {
        blockedBy == loggedInUserArn ?
          setUpdatedConnections({ isBlocked: false, isBlockedLink: true}) :
          setUpdatedConnections({ isBlocked: true, isBlockedLink: false})
      } else {
        setUpdatedConnections({ isBlocked: connections.isBlocked, isBlockedLink: connections.isBlockedLink})
      }
      return channelDetails
    } return null
  }

  const callbackProcessor = async(message: MessageResponse) => {
    const messageType = message?.headers['x-amz-chime-event-type']
    const record = JSON.parse(message?.payload)
    const isRecordNotEmpty = record && record?.Content
    switch(messageType) {
      case 'CREATE_CHANNEL_MESSAGE':
        if(isRecordNotEmpty) {
          if(utils.checkIfBlockIndicatorMessage(record.Content)) {
            fetchChannel()
          }
          handleLoadMessages()
          setShouldLoadMessages(true)
        }
        break
      case 'CREATE_CHANNEL_MEMBERSHIP':
      case 'UPDATE_CHANNEL':
        fetchChannel()
        handleLoadMessages()
        setShouldLoadMessages(true)
        break
      case 'REDACT_CHANNEL_MESSAGE':
      case 'UPDATE_CHANNEL_MESSAGE':
        fetchChannel()
        handleLoadMessages()
        setShouldLoadMessages(true)
        break
    }
  }

  const mapChannelDetails = async () => { // previously setChannelInfo()
    if(channel) {
      const listMessages = await utils.listChannelMessages(channel.ChannelArn, loggedInUserArn)
      const messages = listMessages && listMessages.Messages ? listMessages.Messages : null
      const channelData: any = {
        [channel.Name]: {
          channelArn: channel.ChannelArn,
          totalUnreadCount: 0,
          messageList: messages,
          userInfo: sender
        }
        }
        setChannelReducer(channelData)
        dispatch(setChannelData(channelData))
    } else {
       const channelData: any = {
                [channel.Name]: {
                  channelArn: null,
                  totalUnreadCount: 0,
                  messageList: [],
                  userInfo: sender
                }
              }
        setChannelReducer(channelData)
        dispatch(setChannelData(channelData))
    }
  }

  const handleLoadMessages = async () => {
    if (channelData && channelData?.channelArn) {
      try {
        const listMessages = await utils.listChannelMessages(channelData.channelArn, loggedInUserArn)
        const messages = listMessages?.Messages || null
        if (messages) {
          const updatedChannelsReducer = { ...channelsReducer }
          const updatedMessageList = [...messages]
          updatedChannelsReducer[channel.Name] = {
            ...updatedChannelsReducer[channel.Name],
            messageList: updatedMessageList
          }
          dispatch(setChannelData(updatedChannelsReducer))
        }
      } catch (error) {
        console.error('Error loading messages:', error)
      }
    }
  };

  const handleEndConversation = async() => {
    if(channel.ChannelArn) {
      const actionMarker = isEndedConversation ? MessagingConstants.REENABLE_CONVERSATION_MARKER : MessagingConstants.END_CONVERSATION_MARKER
      if (loggedInUserArn) {
        const res = await utils.updateChannelMarkerBackend(
          channel.ChannelArn,
          loggedInUserArn,
          adminID,
          actionMarker,
          null)
        setIsEndedConversation(!isEndedConversation)
        setEndOrReenableActionDoer(loggedInUserArn)
        window.parent.postMessage({ type: actionMarker, jobseekerId: receiver.external_user_id, jobId: job.job_id }, '*');
        handleLoadMessages()
      }
    }
  }

  return (loggedInUserArn) ? (
    <>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"/>
      <ThemeProvider theme={theme}>
        <div className={ isMobile ? classes.mainQuickConnectContainerMobile : classes.mainQuickConnectContainer}>
          <Grid  >
            <Grid item>
              <Navbar
                receiver={receiver}
                userType={"Jobseeker"}
                username={getDisplayName(receiver, true)}
                onEndConversation={handleEndConversation}
                isEndedConversation={isEndedConversation}
                token={token}
                connections={connections}
                userArn={sender.messaging_id}
                endOrReenableActionDoer={endOrReenableActionDoer}
                job={job}
                deviceType={deviceType}
                channelMetadata={channel?.metadata}
              />
            </Grid>

            {showChat ? (
              <>
                <Grid item>
                  <div className={isMobile ? classes.quickConnectChatBodyMobile : classes.quickConnectChatBody}>
                    {isMobile && (
                      <Grid
                        item
                        container
                        alignContent='center'
                        className={classes.chatDescriptionContainer}
                        sx={{ marginBottom: "5px", cursor: "pointer"}}
                        onClick={toggleJobDescription}>
                        <Typography className={`${classes.chatDescription} ${classes.multiLine}`}>
                          <span className={classes.chevronContainer}>
                            <span className={classes.primaryText}> Applied for
                              <span className={classes.textCapitalize}>{' ' + job?.title}</span> @
                              <span className={classes.textCapitalize}>{' ' + job?.companyName}</span>
                            </span>
                            <ExpandMoreOutlinedIcon className={classes.chevronIcon} />
                          </span>
                        </Typography>
                      </Grid>
                    )}

                    {isJobDescriptionOpen && isMobile && (
                      <>
                        <JobDescription platform="mobile" apply_date={apply_date} handleClose={handleCloseJobDescription} job={job}/>
                        <div className={classes.overlay}></div>
                      </>
                    )}

                    <ChatList
                      channelArn={channel?.ChannelArn || null}
                      channelName={channel?.Name}
                      senderUserArn={loggedInUserArn}
                      isEndedConversation={isEndedConversation}
                      userType={"Jobseeker"}
                      onLoadMessages={handleLoadMessages}
                      connections={updatedConnections}
                      inputFocused={false}
                      timezone={selectedTimezone}
                      channel={updatedChannel}
                      channelMetadata={channel.metadata ? JSON.parse(channel.metadata) : null}
                      endOrReenableActionDoer={endOrReenableActionDoer}
                      receiver={receiver}
                      job={job}
                      sender={sender}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <ChatInput
                    onFocus={() => {}}
                    connections={updatedConnections}
                    channel={{
                      channelArn: channel.ChannelArn || null,
                      channelName: channel.Name,
                      metadata: channel.metadata,
                      isEndedConversation: isEndedConversation
                    }}
                    updatedChannel={updatedChannel}
                    job={job}
                    receiver={{
                      info: {
                        first_name: receiver.first_name,
                        last_name: receiver.last_name,
                        email:"",
                        mobile:  null
                      },
                      externalUserId: receiver.messaging_id,
                      role: "Recruiter",
                      userArn: receiver.messaging_id
                    }}
                    sender={{
                      info: {
                        first_name: sender.first_name,
                        last_name: sender.last_name,
                        email: "",
                        mobile:  null
                      },
                      externalUserId: sender.messaging_id,
                      role: "Jobseeker",
                      userArn: sender.messaging_id
                    }}
                    onLoadMessages={handleLoadMessages}
                    adminID={adminID}
                    endOrReenableActionDoer={endOrReenableActionDoer}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </div>
      </ThemeProvider>
    </>
  ) : null
}

export default Chat
