import { Box, Button, Typography, Link, ThemeProvider, useMediaQuery, Container } from '@mui/material';
import theme from '../../theme';
import Footer from '../Footer';
import { MessagingConstants } from '../../lib/enums';
import { IProps } from './types';

const HomePage = (props: IProps) => {
  const isMobile = useMediaQuery("(max-width: 700px)");
  const url = window.location.href;
  const urlObj = new URL(url);
  const deviceType =  urlObj.searchParams.get("device") || "web";
  const appLink = deviceType === 'web-ios' ? MessagingConstants.IOS_JS_APP_LINK
    : deviceType === 'web-android' ? MessagingConstants.ANDROID_JS_APP_LINK
    : MessagingConstants.WEB_JS_APP_LINK;

  const contactSupport = () => {
    const email = 'jobseeker-support@jobtarget.com';
    const mailtoLink = `mailto:${encodeURIComponent(email)}`;
    if (deviceType === 'web-ios') {
      // iOS specific handling
      const windowRef = window.open(mailtoLink);
      if (!windowRef) {
        window.location.href = mailtoLink;
      }
    } else {
      window.location.href = mailtoLink;
    }
  }

  return (
    <>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"/>
    <ThemeProvider theme={theme}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${isMobile ? '150px' : '120px'})`,
        // bgcolor: '#f5f5f5',
        padding: 4,
      }}
    >
      <Box
        component="img"
        src="/images/jt-logo.svg"
        alt="jt-header"
        sx={{ maxWidth: 400,  marginBottom: 4 }}
      />

      <Button
        onClick={props.onContinue}
        variant="contained"
        color="primary"
        sx={{
          marginBottom: 2,
          width: '100%',
          maxWidth: 300,
          textTransform: 'none',
          fontFamily: 'Poppins',
          fontWeight: 400,
        }}
      >
        Continue
      </Button>

      <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
        OR
      </Typography>

      <a href={appLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            marginBottom: 4,
            width: 300,
            maxWidth: 300,
            textTransform: 'none',
            fontFamily: 'Poppins',
            fontWeight: 400,
            padding: '4px 12px'
          }}
        >
          Register with JobTarget Jobseeker
        </Button>
      </a>
      <Typography variant="body2" fontSize="14px" color="text.secondary">
        Can't access the message?{' '}
        <Link onClick={contactSupport} color="primary" underline="hover" style={{ cursor: 'pointer' }}>
          Contact Us
        </Link>
      </Typography>
    </Box>
    <Footer isMobile={isMobile}/>
    </ThemeProvider>
    </>
  );
};

export default HomePage;