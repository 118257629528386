import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Link, ThemeProvider } from "@mui/material";
import theme from "../../theme";
import { IProps } from './types'

const TermsOfUse = (props: IProps) => {
  const { open, onClose, onAccept } = props

  return (
    <>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"/>
    <ThemeProvider theme={theme}>
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "8px",
          padding: "16px",
        },
      }}
    >
      {/* <DialogTitle sx={{ fontWeight: "500", fontSize: "21px", textAlign: "left", mb: 1, padding: "16px 24px" }}> Terms of Use </DialogTitle> */}
      <DialogContent sx={{ textAlign: "left", padding: '16px 24px 0px 24px' }}>
        <Typography variant="body2"   sx={{ fontSize: '14px' }}>
          By clicking Accept, you agree to JobTarget’s{" "}
          <Link href="https://www.jobtarget.com/terms-of-use" target="_blank" underline="hover" color="primary" sx={{ fontSize: '14px' }}>
            Terms of Use
          </Link>{" "}
          and{" "}
          <Link href="https://www.jobtarget.com/privacy-policy" target="_blank"  underline="hover" color="primary" sx={{ fontSize: '14px' }}>
            Privacy Policy
          </Link>.
        </Typography>
      </DialogContent>

      <DialogActions sx={{ display: "flex", flexDirection: "column", gap: 1, padding: "24px 16px" }}>
        <Button
          onClick={onAccept}
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            fontSize: "14px",
            textTransform: "none",
            borderRadius: "4px",
            padding: '8px 8px',
            fontFamily: 'Poppins',
            fontWeight: 400
          }}
        >
          Accept
        </Button>
        <Button
          onClick={onClose}
          variant="outlined"
          color="inherit"
          fullWidth
          sx={{
            fontSize: "14px",
            textTransform: "none",
            borderRadius: "4px",
            borderColor: "#ccc",
            color: "grey",
            padding: '8px 8px',
            fontFamily: 'Poppins',
            fontWeight: 400
          }}
        >
          Decline
        </Button>
      </DialogActions>
    </Dialog>
    </ThemeProvider>
    </>
  );
};

export default TermsOfUse;