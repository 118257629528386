import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, List, Typography } from '@mui/material'
import { useStyles } from '../../style/styles'
import { IProps } from './types'
import ChatMessage from '../ChatMessage'
import { checkIfBlockIndicatorMessage, deleteChannelMessage, isIndicatorMessage, removeFromScreen, splitUserArn, updateReadMarker, userArn } from '../../lib/messageUtils'
import { ChannelDetails, RootState } from '../../redux/channel/types'
import { useSelector } from 'react-redux'
import { MessagePayload } from '../Chat/types'
import { MessagingConstants } from '../../lib/enums'
import  Fab  from '@mui/material/Fab'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

function ChatList(props: IProps) {
  const classes = useStyles()
  const channelReducer = useSelector((state: RootState) => state.channels.channels)
  // const channelReducer = useSelector((state: ChannelDetails) => state)
  const [messageList, setMessageList] = useState<MessagePayload[] | null>(null)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [fixedChatListHeight, setFixedChatListHeight] = useState(0)
  const [displayBtn, setDisplayBtn] = useState(false);
  const [lastMessage, setLastMessage] = useState<any>({})
  const [isBlockedConvo, setIsBlockedConvo] = useState<boolean>(false)
  const [hasBlocked, setHasBlocked] = useState<boolean>(false)

  const {
    channel,
    channelArn,
    isEndedConversation,
    senderUserArn,
    channelName,
    userType,
    onLoadMessages,
    connections,
    inputFocused,
    timezone,
    channelMetadata,
    endOrReenableActionDoer,
    receiver,
    job,
    sender
  } = props

  const chatListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if(connections.isBlocked || connections.isBlockedLink) {
      if(connections.isBlocked) {
        setIsBlockedConvo(true)
        setHasBlocked(false)
      } else {
        setIsBlockedConvo(true)
        setHasBlocked(true)
      }
    } else {
      if(channel && channel?.ChannelSummary?.Metadata) {
        const parsedMetadata = JSON.parse(channel.ChannelSummary?.Metadata)
        if(parsedMetadata?.BlockedBy && parsedMetadata?.BlockedBy?.trim() !== '') {
          setIsBlockedConvo(true)
          setHasBlocked(false)
          window.parent.postMessage({ type: MessagingConstants.END_CONVERSATION_MARKER, jobseekerId: receiver.external_user_id, jobId: job.job_id }, '*');
          if(parsedMetadata?.BlockedBy == senderUserArn) {
            setHasBlocked(true)
          }
        } else if((parsedMetadata?.EndConversationTimestamp && parsedMetadata?.EndConversationTimestamp?.trim() !== '')) {
          window.parent.postMessage({ type: MessagingConstants.END_CONVERSATION_MARKER, jobseekerId: receiver.external_user_id, jobId: job.job_id }, '*');
        } else {
          setIsBlockedConvo(false)
          setHasBlocked(false)
          window.parent.postMessage({ type: MessagingConstants.REENABLE_CONVERSATION_MARKER, jobseekerId: receiver.external_user_id, jobId: job.job_id }, '*');
        }
      } else {
        setIsBlockedConvo(false)
        setHasBlocked(false)
      }
    }
    
    const channelFromReducer = channelReducer[channelName]
    if (channelFromReducer?.messageList?.length > 0) {
      filterMessageList()
    }
  }, [channelReducer, channel])

  const handleScrollToBottom = () => {
    if (chatListRef.current) {
      chatListRef.current.scrollTop = chatListRef.current.scrollHeight
    }
  }

  const handleScroll = () => {
    if (chatListRef.current) {
      const scrollTop = chatListRef.current.scrollTop
      const scrollHeight = chatListRef.current.scrollHeight
      const clientHeight = chatListRef.current.clientHeight
      const currentScroll = scrollTop + clientHeight
      const chatListHeight = scrollHeight - clientHeight


      setScrollPosition(currentScroll);
      setFixedChatListHeight(chatListHeight)

      if (currentScroll <= chatListHeight) {
        setDisplayBtn(true);
      } else {
        setDisplayBtn(false);
      }
    }
  };

  /* Scroll to bottom when component mounts or when messageList updates */
  useEffect(() => {
    if (chatListRef.current) {
      chatListRef.current.scrollTop = chatListRef.current.scrollHeight
    }
  }, [messageList, chatListRef])

  useEffect(() => {
    const scrollElement = chatListRef.current;
    if(scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll);
      return () => {
        scrollElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const filterMessageList = async() => {
    // add redacted to message list
    let messageListTemp = channelReducer[channelName]?.messageList
    setLastMessage(messageListTemp[messageListTemp.length - 1])
    let filteredMessages = messageListTemp.filter(message => {
      const { Redacted, Metadata, Sender, Content } = message
      if(Redacted) {
        return false
      } else {
        if(Metadata === null) {
          return true
        } else {
            if(checkIfBlockIndicatorMessage(Content) ) {
              return false
            }
            else{
              if (JSON.parse(Metadata).removedFromScreenTimestamp === null) {
                return true
              } else {
                if( splitUserArn(Sender.Arn) == senderUserArn) {
                  return true
                } else {
                  if(isIndicatorMessage(Content)) {
                    return true
                  } else {
                    return false
                  }
                }
              }
            }
          }
      }
    });
    setMessageList(filteredMessages)
  }

  const handleRemoveOrUndoMessage = async(
    channelArn: string,
    messageId: string,
    content: string,
    userId: string,
    removeType: string) => {
    let isSuccessful = null

    if(removeType == MessagingConstants.REMOVE_FROM_SCREEN) {
      isSuccessful = await removeFromScreen(
        channelArn,
        content,
        messageId,
        userId,
        MessagingConstants.REMOVED_FROM_SCREEN_TIMESTAMP
      )
    } else if(removeType == MessagingConstants.UNDO_REMOVE_MESSAGE) {
      isSuccessful = await removeFromScreen(
        channelArn,
        content,
        messageId,
        userId,
        MessagingConstants.UNDO_REMOVE_MESSAGE
      )
    } else if(removeType == MessagingConstants.REMOVE_FOR_EVERYONE) {
      isSuccessful = await deleteChannelMessage(
        channelArn,
        messageId,
        userId
      )
    } else {
      throw ('RemoveType is not valid' + removeType)
    }

    onLoadMessages()
    // remove redacted in messageList
    if (messageList) {
      setMessageList(messageList.filter((message, index) => message.MessageId !== messageId))
    }
  }

  const handleBodyClick = () => {
    if(channelArn && channelArn !== '') {
      if(messageList && messageList.length > 0) {
        const lastMessage = messageList[messageList.length - 1]
        if (lastMessage && lastMessage.Sender && splitUserArn(lastMessage.Sender.Arn) === senderUserArn) {
          updateReadMarker(senderUserArn, channelArn, true)
        } else {
          updateReadMarker(senderUserArn, channelArn, false)
        }
      }
    }
  }

  return (
    <div className={ props.platform == 'mobile' ? classes.mobileChatListContainer : classes.chatListContainer} ref={chatListRef} onClick={handleBodyClick}>
      <div className={classes.innerChatBody}>
      <div className={classes.messageWrapper}>
        <List>
          {messageList && messageList.length > 0 && messageList.map((message, index) => (
            <ChatMessage
              key={index}
              message={message}
              prevMessage={index > 0 && messageList ? messageList[index - 1] : null}
              userArn={senderUserArn}
              isEndedConversation={isEndedConversation}
              isLastMessage={index === messageList.length - 1}
              userType={userType}
              channelArn={channelArn || null}
              handleDeleteMessage={handleRemoveOrUndoMessage}
              connections={connections}
              timezone={timezone}
              channelMetadata ={channelMetadata ?? ''}
              endOrReenableActionDoer={endOrReenableActionDoer}
              lastMessage={lastMessage}
              // platform='mobile' // recheck
            />
          ))}
          {/* TO REVERT: SCROLL KEY */}
          {/* {displayBtn && (
            <Box
              sx={{
                display: 'flex',
                position: 'fixed',
                right: '30px',
                bottom: '70px',
                padding: '5px'
              }}>
              <Fab
                sx={{
                  backgroundColor: 'white',
                  color: '#2F86DD'
                }}
                hidden={displayBtn} size='small'
                onClick={handleScrollToBottom}>
                <KeyboardArrowDownIcon />
              </Fab>
            </Box>
          )} */}
        </List>

        { (isBlockedConvo || !sender.termsAgreed) && (
          <div className={classes.messageContentEndConversationWrapper}>
            {/* for blocked convo */}
            {isBlockedConvo && (
              <div className={classes.messageContentEndConversation}>
              <Typography className={classes.messageTextEndConversation}>
                {hasBlocked ? (
                  <>You have blocked this user.</>
                ) : (
                  <>You have been blocked by this user.</>
                )}
              </Typography>
            </div>
            )}
            
          </div>
      )}

      </div>

         
      </div>
    </div>
  )
}

export default ChatList