import { makeStyles } from '@mui/styles'
import './globalStyles.css'

export const useStyles = makeStyles({
  chatDescription: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
    color: '#3F4254',
    padding: '0px',
    width: '100%',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  chatDescriptionContainer: {
    backgroundColor: '#EAF3FC',
    width: '100%',
    gap: '16px',
    top: '72px',
    left: '1px',
    padding: '0px 24px',
    overflow: 'hidden',
    minHeight: '40px',
    height: 'auto',
    maxHeight: 'calc(3 * 22px + 16px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  mobileChatDescription: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    width: '100%',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Poppins',
    padding: '10px',
  },
  mobileChatDescriptionContainer: {
    width: '100%',
    gap: '16px',
    top: '72px',
    left: '1px',
    padding: '0px 24px',
    overflow: 'hidden',
    minHeight: '50px',
    height: 'auto',
    maxHeight: 'calc(3 * 22px + 16px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderBottom: '1px solid #E0E0E0'
  },
  singleLine: {
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  multiLine: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  chatPanel: {
    display: 'flex',
    flexDirection: 'column',
    msFlexDirection: 'column',
    WebkitFlexDirection: 'column',
    alignItems: 'flex-start',
    padding: '1px',
    position: 'fixed',
    bottom: 3,
    right: 3,
    width: '385px',
    height: '500px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    boxShadow: '0px 11px 15px rgba(13, 45, 77, 0.05), 0px 24px 38px rgba(13, 45, 77, 0.035), 0px 9px 46px rgba(13, 45, 77, 0.03)',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '14px',
    transition: 'transform 0.2s ease-in-out',
    transform: 'translateY(100%)',
    '&.show': {
      transform: 'translateY(0)'
    },
    zIndex: 9998,
    cursor: 'default'
  },
  chatNavbar: {
    height: 'var(--default-navbar-height)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  chatBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: 'calc(100vh - var(--default-navbar-height) - var(--default-input-height))'
  },
  quickConnectChatBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: 'calc(100vh - 160px - var(--default-input-height) - var(--default-navbar-height))',
    minHeight: 0
  },
  quickConnectChatBodyMobile: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    // height: 'calc(100vh - 50px - var(--default-input-height))',
    height: 'var(--default-quick-connect-chat-body-mobile-height)',
    minHeight: 0
  },
  innerChatBody: {
    marginLeft: '24px',
    marginRight: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100%'
  },
  messageWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  messageContentEndConversationWrapper: {
    marginTop: 'auto',
  },
  chatListContainer: {
    width: '100%',
    flex: 1,
    overflowY: 'auto',
    borderBottom: '1px solid #F2F3F7',
    backgroundColor: 'white'
  },
  mobileChatListContainer: {
    width: '100%',
    flex: 1,
    overflowY: 'auto',
    borderBottom: '1px solid #F2F3F7',
    backgroundColor: 'white'
  },
  chatInputWeb: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    bottom: 0,
    width: '100%',
    backgroundColor: 'white',
    zIndex: 1000,
    height: 'var(--default-input-height)',
    flex: 'none'
  },
  chatInput: {
    display: 'flex',
    flexDirection: 'column',
    msFlexDirection: 'column',
    WebkitFlexDirection: 'column',
    alignItems: 'flex-start',
    WebkitAlignItems: 'flex-start',
    width: '100%',
    height: 'var(--default-input-height)',
    backgroundColor: 'white',
    flex: 'none',
    alignSelf: 'stretch',
    flexGrow: 0
  },
  contentCenter: {
    alignContent: 'center',
    alignSelf: 'center',
  },
  deletedMessageText: {
    color: 'gray',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '22px',
  },
  endOrReenabledMarker: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignContent: 'center',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    color: '#B5B5C3',
  },
  footer: {
    position: 'fixed',
    bottom: '0px',
    width: '100%',
    backgroundColor: '#f0f0f0',
    padding: '10px',
    boxSizing: 'border-box',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: 'white',
    overflow: 'hidden'
  },
  mainQuickConnectContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: 'white',
    overflow: 'hidden'
  },
  mainQuickConnectContainerMobile: {
    // display: 'flex',
    // flexDirection: 'column',
    // // height: '100%',
    // minHeight: 'calc(100vh - 50px)',
    // // marginTop: '50px',
    // backgroundColor: 'white',
    // overflow: 'hidden'
  },
  messageContentEndConversation: {
    display: 'flex',
    padding: '8px',
    fontSize: '14px',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    background: '#FFE2E5',
  },
  messageTextEndConversation: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 0',
    color: 'var(--danger-dark-802932, #802932)',
    fontSize: '14px',
    fontFamily: 'Poppins',
    lineHeight: '22px',
  },
  textCapitalize: {
    textTransform: 'capitalize'
  },
  primaryText: {
    color: '#2F86DD',
    fontSize: '12px'
  },
  chevronContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  chevronIcon: {
    marginLeft: 'auto',
    fontWeight: 200,
    fontSize: "12px",
    color: '#2F86DD',
  },
  messageContainer: {
    alignItems: 'flex-start',
    marginBottom: '8px',
    marginRight: '10px',
    marginLeft: '10px'
  },
  avatar: {
    width: '40px',
    height: '40px',
  },
  messageContentSelf: {
    display: 'flex',
    flexDirection: 'row',
    msFlexDirection: 'row',
    WebkitFlexDirection: 'row',
    alignItems: 'flex-start',
    padding: '10px',
    gap: '8px',
    background: '#2F86DD',
    borderRadius: '12px',
    border: '1px solid #1E6DBB',
    maxWidth: 'max-content',
    fontSize: '14px'
  },
  messageContentOther: {
    display: 'flex',
    flexDirection: 'row',
    msFlexDirection: 'row',
    WebkitFlexDirection: 'row',
    alignItems: 'flex-start',
    padding: '10px',
    gap: '8px',
    borderRadius: '12px',
    border: '1px solid #C0DAF5',
    maxWidth: 'max-content',
    fontSize: '14px'
  },
  messageTextSelf: {
    color: '#FFFFFF',
    fontSize: '14px'
  },
  messageTextOther: {
    color: 'black',
    fontSize: '14px'
  },
  badgeNumber: {
    position: 'relative',
    top: '-20px',
    right: '-8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '15px',
    height: '15px',
    borderRadius: '50%',
    backgroundColor: 'red',
    color: 'white',
    fontSize: '10px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
  },
  messageBodyContainer: {
    display: 'flex',
    marginBottom: '8px'
  },
  normalText: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
  },
  timeMessageContainer: {
    width: '100%'
  },
  undoButton: {
    backgroundColor: '#ffffff',
    border: '0px',
    color: '#0078d4',
    fontSize: '12px',
    padding: '2px 8px',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',

    '&:hover': {
      backgroundColor: '#f3f2f1',
      color: '#005a9e',
    },

    '&:active': {
      backgroundColor: '#e1dfdd',
      color: '#004578',
    },

    '&:focus': {
      outline: 'none',
    },
  },
  minimizedChat: {
    width: '100%',
    display: 'flex',
    height: '40px',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#2F86DD',
    color: '#ffffff',
    padding: '10px 20px',
    position: 'fixed',
    bottom: 0,
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  headerName: {
    cursor: 'default',
    flexGrow: 1,
    display: 'flex',
    textTransform: 'capitalize',
    fontSize: '19px',
    lineHeight: '24px',
    fontWeight: '500',
    width:  '50%',
    transition:   'width 0.3s ease-in',
    textAlign: 'left'
  },
  minimizedHeader: {
    cursor: 'default',
    flexGrow: 1,
    display: 'flex',
    textTransform: 'capitalize',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '500',
    width:  '50%',
    transition:   'width 0.3s ease-in',
    textAlign: 'left'
  },
  unreadBadge: {
    borderRadius: '1000px',
    background: 'var(--danger-default-f-64-e-60, #F64E60)',
    color: 'white',
    width: '20px',
    height: '20px',
    padding: '2px',
    fontSize: '12px',
    marginLeft: '5px',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center'
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  /* QUICK CONNECT STYLING */
  quickConnectMainContainer: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden"
  },
  quickConnectMainContainerMobile: {
    height: "calc(100dvh - 50px)", // Using dynamic viewport height
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    marginTop: '50px',
    paddingBottom: 'env(safe-area-inset-bottom)' // Add safe area for iOS
  },
  quickConnectHeader: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "50px",
    backgroundColor: "#fff",
    borderBottom: "1px solid #ddd",
    zIndex: 1000,
  },
  testStyle: {
    
  },
  quickConnectBody: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    marginTop: "50px",
    marginBottom: "70px",
    gap: "20px",
    padding: "20px",
    backgroundColor: "#f4f4f4",
    overflow: "auto",
    maxHeight: "calc(100vh - 120px)"
  },
  quickConnectBodyMobile: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: 0,
    backgroundColor: "#f4f4f4",
    height: "calc(100dvh - 50px - env(safe-area-inset-bottom))", // Dynamic viewport height with safe area
    overflow: "hidden",
    position: 'relative'
  },
  quickConnectChat: {
    flex: 1,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    borderRadius: "5px",
    overflow: "hidden",
    minHeight: 0,
    height: "100%"
  },
  quickConnectChatMobile: {
    flex: 1,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    borderRadius: "5px",
    overflow: "hidden",
    height: "calc(100dvh - 50px - env(safe-area-inset-bottom))" // Dynamic viewport height with safe area
  },
  quickConnectJobDescription: {
    flex: 1,
    minHeight: 0,
    maxHeight: "calc(100vh - 120px)",
    border: "1px solid #ddd",
    borderRadius: "5px",
    overflowY: "auto",
    backgroundColor: "#fff"
  },
  quickConnectFooterMobile: {
    // position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "152px",
    backgroundColor: "#fff",
    borderTop: "1px solid #ddd",
    zIndex: 1000,
    padding: "0px 20px",
    fontSize: "14px",
    color: "#666",
  },
  quickConnectFooter: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "70px",
    backgroundColor: "#fff",
    borderTop: "1px solid #ddd",
    zIndex: 1000,
    padding: "0px 20px",
    fontSize: "14px",
    color: "#666",
  },
  jobDescriptionMainTitle: {
    fontSize: '26px',
    fontWeight: '600',
    margin: 0
  },
  jobDescriptionSubTitle: {
    fontSize: '14px',
    fontWeight: '500',
    marginBottom: 0
  },
  jobModal: {
    position: "absolute",
    zIndex: '9999',
    bottom: 0,
    left: 0,
    right: 0,
    height: "80%",
    backgroundColor: "#fff",
    boxShadow: "0px -4px 6px rgba(0, 0, 0, 0.1)",
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
    transform: "translateY(100%)",
    animation: "slideUp 0.4s ease-out forwards",
    overflowY: "auto",
  },
  jobModalContent: {
    padding: "16px",
  },
  heading: {
    marginBottom: "8px",
  },
  paragraph: {
    marginBottom: "16px",
  },
  list: {
    paddingLeft: "20px",
    listStyleType: "disc",
    marginBottom: "16px",
  },
  scrollbar: {
    width: "8px",
  },
  scrollbarThumb: {
    backgroundColor: "#ccc",
    borderRadius: "4px",
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9998,
  },
  jobDescriptionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    top: 0,
    left: 0,
    width: 'calc(100% - 16px)',
    backgroundColor: '#fff',
    zIndex: 1000,
    padding: '16px 0',
    boxShadow: '0 2px 0px rgba(0, 0, 0, 0.1)',
    color: '#212529'
  },
  centeredStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  centeredSpinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh'
  },
  noTextDecorations: {
    color: 'var(--primary-text-color, #000)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    textDecoration: 'none',
  },
  invalidAccesContainer: {
    display: 'flex',
    flexDirection:'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  },
})
